<template>
  <div>
    <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
    <span class="leading-none font-medium">SEO Info</span>

    <vs-input
      class="w-full mt-4"
      label="Meta Title"
      :value="form.meta_page_title"
      @input="updateForm({ key: 'meta_page_title', value: $event })"
    />

    <vs-input
      class="w-full mt-4"
      label="Meta Keywords"
      :value="form.meta_keywords"
      @input="updateForm({ key: 'meta_keywords', value: $event })"
    />

    <div class="w-full mt-4">
      <vs-textarea
        class="w-full mt-4"
        label="Meta Description"
        :value="form.meta_description"
        @input="updateForm({ key: 'meta_description', value: $event })"
      />
    </div>
  </div>
</template>


<script>
import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    vSelect,
  },
  computed: {
    ...mapGetters({
      form: "homestay/getFormObj",
      validation: "validation/getValidationErrors",
    }),
  },
  methods: {
    ...mapActions({
      updateForm: "homestay/updateForm",
    }),
  },
};
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
