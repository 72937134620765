<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Homestay Details</span>
      </div>
      <div class="vx-col w-full">
        <vs-input
          class="w-full mt-4"
          label="Homestay Name"
          :danger="validation.name ? true : false"
          :danger-text="validation.name ? validation.name[0] : ''"
          :value="form.name"
          @input="updateForm({ key: 'name', value: $event })"
        />
      </div>
      <div class="vx-col w-full md:w-3/3">
        <vs-input
          class="w-full mt-4"
          label="Slug"
          :danger="validation.slug ? true : false"
          :danger-text="validation.slug ? validation.slug[0] : ''"
          :value="form.slug"
          @input="updateForm({ key: 'slug', value: $event })"
        />
      </div>

      <div class="vx-col w-full md:w-1/3">
        <label class="vs-input--label">Vendor</label>
        <v-select
          :danger="validation.user_id ? true : false"
          :value="getFromGeo.user_id"
          @input="updateForm({ key: 'user_id', value: $event })"
          :options="users"
        />
        <span
          class="text-danger text-sm"
          v-show="validation.user_id ? true : false"
        >
          {{ validation.user_id ? validation.user_id[0] : "" }}
        </span>
      </div>

      <div class="vx-col w-full md:w-1/3">
        <label class="vs-input--label">Room Type</label>
        <v-select
          :danger="validation.room_type ? true : false"
          :value="formDropDown.room_type"
          @input="updateForm({ key: 'room_type', value: $event })"
          :options="room_types"
        />
        <span
          class="text-danger text-sm"
          v-show="validation.room_type ? true : false"
        >
          {{ validation.room_type ? validation.room_type[0] : "" }}
        </span>
      </div>

      <div class="vx-col w-full md:w-1/3">
        <label class="vs-input--label">Property Type</label>
        <v-select
          :danger="validation.property_type ? true : false"
          :value="formDropDown.property_type"
          @input="updateForm({ key: 'property_type', value: $event })"
          :options="property_types"
        />
        <span
          class="text-danger text-sm"
          v-show="validation.property_type ? true : false"
        >
          {{ validation.property_type ? validation.property_type[0] : "" }}
        </span>
      </div>

      <div class="vx-col w-full md:w-1/3">
        <vs-input
          class="w-full mt-4"
          label="Email"
          :danger="validation.email ? true : false"
          :danger-text="validation.email ? validation.email[0] : ''"
          :value="form.email"
          @input="updateForm({ key: 'email', value: $event })"
        />
      </div>

      <div class="vx-col w-full md:w-1/3">
        <vs-input
          class="w-full mt-4"
          label="Website"
          :danger="validation.website ? true : false"
          :danger-text="validation.website ? validation.website[0] : ''"
          :value="form.website"
          @input="updateForm({ key: 'website', value: $event })"
        />
      </div>

      <div class="vx-col w-full md:w-1/3">
        <vs-input
          class="w-full mt-4"
          label="Primary Phone"
          :danger="validation.phone_1 ? true : false"
          :danger-text="validation.phone_1 ? validation.phone_1[0] : ''"
          :value="form.phone_1"
          @input="updateForm({ key: 'phone_1', value: $event })"
        />
      </div>

      <div class="vx-col w-full md:w-1/3">
        <vs-input
          class="w-full mt-4"
          label="Secondary Phone"
          :danger="validation.phone_2 ? true : false"
          :danger-text="validation.phone_2 ? validation.phone_2[0] : ''"
          :value="form.phone_2"
          @input="updateForm({ key: 'phone_2', value: $event })"
        />
      </div>

      <div class="vx-col w-full md:w-1/3">
        <vs-input
          class="w-full mt-4"
          label="Primary Address"
          :danger="validation.address_1 ? true : false"
          :danger-text="validation.address_1 ? validation.address_1[0] : ''"
          :value="form.address_1"
          @input="updateForm({ key: 'address_1', value: $event })"
        />
      </div>

      <div class="vx-col w-full md:w-1/3">
        <vs-input
          class="w-full mt-4"
          label="Secondary Address"
          :danger="validation.address_2 ? true : false"
          :danger-text="validation.address_2 ? validation.address_2[0] : ''"
          :value="form.address_2"
          @input="updateForm({ key: 'address_2', value: $event })"
        />
      </div>

      <div class="vx-col w-full md:w-2/3">
        <label class="vs-input--label">Amenities</label>
        <v-select
          multiple
          :danger="validation.amenities ? true : false"
          :value="getFromGeo.amenities"
          @input="handleMultipleSelect({ key: 'amenities', value: $event })"
          :options="amenities"
        />

        <span
          class="text-danger text-sm"
          v-show="validation.amenities ? true : false"
        >
          {{ validation.amenities ? validation.amenities[0] : "" }}
        </span>
      </div>

      <div class="vx-col w-full md:w-1/3">
        <label class="vs-input--label">Tag</label>
        <v-select
          :danger="validation.tag ? true : false"
          :value="formDropDown.tag"
          @input="updateForm({ key: 'tag', value: $event })"
          :options="tags"
        />
        <span
          class="text-danger text-sm"
          v-show="validation.tag ? true : false"
        >
          {{ validation.tag ? validation.tag[0] : "" }}
        </span>
      </div>

      <div class="vx-col w-full mt-4">
        <label>Available Features</label>
        <v-select
          multiple
          :danger="validation.available_features ? true : false"
          :value="getFromGeo.available_features"
          :options="available_features"
          @input="
            handleMultipleSelect({ key: 'available_features', value: $event })
          "
        />

        <span
          class="text-danger text-sm"
          v-show="validation.available_features ? true : false"
        >
          {{
            validation.available_features
              ? validation.available_features[0]
              : ""
          }}
        </span>
      </div>
    </div>
    <div class="w-full mt-4">
      <label for="Overview">Overview</label>
      <quill-editor
        :options="editorOptions"
        :value="form.description"
        @input="updateForm({ key: 'description', value: $event })"
      ></quill-editor>
      <span
        class="text-danger text-sm"
        v-show="validation.description ? true : false"
      >
        {{ validation.description ? validation.description[0] : "" }}
      </span>
    </div>
    <div class="w-full mt-4">
      <label for="Room">Room</label>
      <quill-editor
        :options="editorOptions"
        :value="form.room_description"
        @input="updateForm({ key: 'room_description', value: $event })"
      ></quill-editor>
      <span
        class="text-danger text-sm"
        v-show="validation.room_description ? true : false"
      >
        {{ validation.room_description ? validation.room_description[0] : "" }}
      </span>
    </div>
    <div class="w-full mt-4">
      <label for="Cancellation">Cancellation</label>
      <quill-editor
        :options="editorOptions"
        :value="form.cancellation_description"
        @input="updateForm({ key: 'cancellation_description', value: $event })"
      ></quill-editor>

      <span
        class="text-danger text-sm"
        v-show="validation.cancellation_description ? true : false"
      >
        {{
          validation.cancellation_description
            ? validation.cancellation_description[0]
            : ""
        }}
      </span>
    </div>
    <div class="w-full mt-4">
      <label for="attraction">Local Tourist Attraction</label>
      <quill-editor
        :options="editorOptions"
        :value="form.local_tourist_attraction"
        @input="updateForm({ key: 'local_tourist_attraction', value: $event })"
      ></quill-editor>

      <span
        class="text-danger text-sm"
        v-show="validation.local_tourist_attraction ? true : false"
      >
        {{
          validation.local_tourist_attraction
            ? validation.local_tourist_attraction[0]
            : ""
        }}
      </span>
    </div>
    <div class="w-full mt-4">
      <label for="facilities">Available facilities</label>
      <quill-editor
        :options="editorOptions"
        :value="form.available_facilities"
        @input="updateForm({ key: 'available_facilities', value: $event })"
      ></quill-editor>

      <span
        class="text-danger text-sm"
        v-show="validation.available_facilities ? true : false"
      >
        {{
          validation.available_facilities
            ? validation.available_facilities[0]
            : ""
        }}
      </span>
    </div>
    <div class="w-full mt-4">
      <label for="infrastructure">Available local infrastructure</label>
      <quill-editor
        :options="editorOptions"
        :value="form.available_local_infrastructure"
        @input="
          updateForm({ key: 'available_local_infrastructure', value: $event })
        "
      ></quill-editor>

      <span
        class="text-danger text-sm"
        v-show="validation.available_local_infrastructure ? true : false"
      >
        {{
          validation.available_local_infrastructure
            ? validation.available_local_infrastructure[0]
            : ""
        }}
      </span>
    </div>
    <div class="w-full mt-4">
      <label for="included">What is included</label>
      <quill-editor
        :options="editorOptions"
        :value="form.what_is_included"
        @input="updateForm({ key: 'what_is_included', value: $event })"
      ></quill-editor>

      <span
        class="text-danger text-sm"
        v-show="validation.what_is_included ? true : false"
      >
        {{ validation.what_is_included ? validation.what_is_included[0] : "" }}
      </span>
    </div>
  </div>
</template>

<script>
import vSelect, { vOption } from "vue-select";
import { mapGetters, mapActions } from "vuex";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
const fullToolbarOptions = [
  [{ header: [1, 2, 3, false] }],
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],
  [{ header: 1 }, { header: 2 }],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ direction: "rtl" }],
  [{ size: ["small", false, "large", "huge"] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ font: [] }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  ["clean"],
  ["link", "image", "video"],
];

export default {
  data() {
    return {
      editorOptions: {
        theme: "bubble",
        modules: {
          toolbar: {
            container: fullToolbarOptions,
          },
        },
      },
    };
  },
  mounted() {
    this.fetchAndSetUsers();
    this.fetchUsers();
    this.fetchRoomTypes();
    this.fetchPropertyTypes();

    let params = new URLSearchParams();
    params.append("query[type]", "homestay");
    this.fetchAmenities({ query: params });
    this.fetchFeatures();
  },

  components: {
    vSelect,
    vOption,
    quillEditor,
  },

  computed: {
    ...mapGetters({
      form: "homestay/getFormObj",
      getFromGeo: "homestay/getFromGeo",
      validation: "validation/getValidationErrors",
      users: "user_dropdown/fetchUsersDropdown",
      amenities: "homestay_amenities/fetchAmenitiesDropdown",
      available_features: "feature_dropdown/fetchFeaturesDropdown",
      formDropDown: "homestay/getFormDropdownObj",
      room_types: "room_type/fetchRoomTypesDropdown",
      property_types: "property_type/fetchPropertyTypesDropdown",
      tags: "homestay/getTags",
    }),
  },
  methods: {
    ...mapActions({
      fetchAmenities: "homestay_amenities/fetchAndSetAmenitiesDropdown",
      fetchAndSetUsers: "homestay/fetchAndSetUsers",
      updateForm: "homestay/updateForm",
      fetchUsers: "user_dropdown/fetchAndSetUsersDropdown",
      fetchFeatures: "feature_dropdown/fetchAndSetFeaturesDropdown",
      fetchRoomTypes: "room_type/fetchAndSetRoomTypesDropdown",
      fetchPropertyTypes: "property_type/fetchAndSetPropertyTypesDropdown",
    }),
    handleMultipleSelect($event) {
      this.updateForm($event);
    },
  },
};
</script>
