<template>
  <div>
     <div>
      <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
      <span class="leading-none font-medium">Additional Info</span>
    </div>

    <vs-input 
      class="w-full mt-4" 
      label="Min Payment Amount" 
      :danger="validation.min_payment_amount?true:false"
      :danger-text="validation.min_payment_amount?validation.min_payment_amount[0]:''"
      :value="form.min_payment_amount"
      @input="updateForm({key: 'min_payment_amount', value: $event})" />

    <vs-input 
      class="w-full mt-4" 
      label="Price Per Person" 
      :danger="validation.price_per_person?true:false"
      :danger-text="validation.price_per_person?validation.price_per_person[0]:''"
      :value="form.price_per_person"
      @input="updateForm({key: 'price_per_person', value: $event})" />
  
    <vs-input 
      class="w-full mt-4" 
      label="Total Person Capacity" 
      :danger="validation.total_person_capacity?true:false"
      :danger-text="validation.total_person_capacity?validation.total_person_capacity[0]:''"
      :value="form.total_person_capacity"
      @input="updateForm({key: 'total_person_capacity', value: $event})" />

    <vs-input 
      class="w-full mt-4" 
      label="Discount Person" 
      :danger="validation.discount_persons?true:false"
      :danger-text="validation.discount_persons?validation.discount_persons[0]:''"
      :value="form.discount_persons"
      @input="updateForm({key: 'discount_persons', value: $event})" />

    <vs-input 
      class="w-full mt-4" 
      label="Discount" 
      :danger="validation.discount?true:false"
      :danger-text="validation.discount?validation.discount[0]:''"
      :value="form.discount"
      @input="updateForm({key: 'discount', value: $event})" />


    <vs-input 
      class="w-full mt-4" 
      label="Position" 
      :danger="validation.position?true:false"
      :danger-text="validation.position?validation.position[0]:''"
      :value="form.position"
      @input="updateForm({key: 'position', value: $event})" />
  </div>
</template>

<script>

import vSelect,{vOption} from 'vue-select'
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {

    }
  },
  components: {
    vSelect,
    vOption
  },
  computed: {
    ...mapGetters({
        form: 'homestay/getFormObj',
        validation: 'validation/getValidationErrors',
        homestay_regions: 'homestay/getHomestayRegionsDropdown',
    })
  },
  methods: {
    ...mapActions({
      updateForm: 'homestay/updateForm',
    }),
  }
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
